<template>
  <div class="body">
    <div class="company_img">
      <img :src="bannerimg" alt="" />
    </div>
    <div class="company_info">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">公司简介</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="company_con" v-html="content">

      </div>

      <!-- <div class="map">
        <img :src="addressimg" alt="">
      </div> -->
    </div>
    <div class="company-map">
  <iframe width="1070" height="478" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/bkrHE1Z1g4Tj"></iframe>
    </div>

  </div>
</template>
  <script src="//webapi.amap.com/maps?v=1.3&key=您申请的key值"></script>
<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      content: "",
      addressimg: "",

      mapConfig: {
        zoom: 15, // 当前缩放层级
        zooms: [14, 18], // 地图缩放层级范围
        center: [115.779166, 33.824559], // 地图中心点
      },
    };
  },
  mounted() {
    this.getcompany();
  },
  created() {},
  methods: {
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 2,
      };
      const data = await getbannerdetail(params);
      this.bannerimg = "http://36.152.65.166:10008" + data.data.image;
      this.content = data.data.children[0].content;
      this.addressimg =
        "http://36.152.65.166:10008" + data.data.children[0].image;
      // console.log(data.data);
    },
  },
};
</script>

<style >
</style>
<style lang="scss" scoped>
.body {
  .company-map {
        border-top: 1px solid #d9d9d9;
    width: 98%;
    display: flex;
    padding: 10px;
    justify-content: center;
  }
  height: 1500px;
  .product_title {
    width: 1500px;
    margin: 10px auto;
    margin-bottom: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .product_tit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .tit1 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 13px;
      }
      .tit2 {
        width: 60px;
        height: 4px;
        background-color: #0088ea;
      }
    }
  }
  .company_img {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .company_info {
    .company_con {
      width: 1070px;
      margin: 0 auto;

      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-align: justify;
      color: #000000;
      text-indent: 40px;
    }
    .map {
      box-sizing: border-box;
      padding-top: 36px;
      border-top: 1px solid #d9d9d9;
      width: 1070px;
      height: 482px;
      margin: 86px auto;
      margin-bottom: 118px;
      img {
        width: 100%;
      }
    }
  }
}
</style>